import axios from "axios";
import { DeliveryWindow } from "@/services/courier.service";

export class BreedDTO {
  constructor(public breedId: number, public name: string) {}

  static buildFromJson(json: Record<string, any>): BreedDTO {
    return new BreedDTO(json.breed_id, json.name);
  }
}

export class AgeDto {
  constructor(public days: number, public months: number, public years: number) {}

  static buildFromJson(json: any): AgeDto {
    return new AgeDto(json.day, json.months, json.years);
  }

  ageDisplay(): string {
    let ageText = "";
    if (this.years === 0 && this.months === 0) {
      return this.days === 1 ? "1 day" : `${this.days} days`;
    }
    if (this.years === 1) {
      ageText += "1 year";
    }
    if (this.years > 1) {
      ageText += `${this.years} years`;
    }
    if (this.months === 1) {
      ageText += ageText ? ", 1 month" : "1 month";
    }
    if (this.months > 1) {
      ageText += ageText ? `, ${this.months} months` : `${this.months} months`;
    }
    return ageText;
  }
}

export class HealthConditionDTO {
  constructor(
    public description: string,
    public displayName: string,
    public healthIssueId: number,
    public name: string
  ) {}

  static buildFromJson(json: any) {
    return new HealthConditionDTO(
      json.description_default,
      json.display_name_default,
      json.health_issue_id,
      json.name_default
    );
  }
}

class SubscriptionDto {
  constructor(public subscriptionId: number, public status: string) {}
}

export enum PetState {
  NEEDS_CHECKOUT,
  COMPLETE_OR_PAUSED,
  CANCELLED,
  OTHER
}

export class PetDataDto {
  constructor(
    public petId: number,
    public customerId: number,
    public age: AgeDto,
    public breed: BreedDTO,
    public name: string,
    public weight: number,
    public status: string,
    public subscription: SubscriptionDto,
    public healthCondition: Array<HealthConditionDTO>,
    public deliveryWindow: DeliveryWindow | null
  ) {}

  private needsCheckout(): boolean {
    return this.subscription.status === "complete" && this.status === "partial";
  }
  private isCompleteOrPaused(): boolean {
    // we don't use 'paused' subscription
    return this.subscription.status === "complete" && this.status === "complete";
  }
  private isCancelled(): boolean {
    return this.subscription.status === "cancelled" && this.status === "complete";
  }

  public getPetState(): PetState {
    if (this.needsCheckout()) return PetState.NEEDS_CHECKOUT;
    if (this.isCompleteOrPaused()) return PetState.COMPLETE_OR_PAUSED;
    if (this.isCancelled()) return PetState.CANCELLED;
    return PetState.OTHER;
  }

  static buildFromJson(json: any): PetDataDto {
    let deliveryWindow = null;
    if (json.delivery_window) {
      deliveryWindow = new DeliveryWindow(json.delivery_window);
    }

    return new PetDataDto(
      json.pet_id,
      json.customer_id,
      AgeDto.buildFromJson(json.age),
      BreedDTO.buildFromJson(json.breed),
      json.name,
      json.weight,
      json.status,
      new SubscriptionDto(json.subscription_id, json.subscription_status),
      json.health_condition.map((item: HealthConditionDTO) =>
        HealthConditionDTO.buildFromJson(item)
      ),
      deliveryWindow
    );
  }
}

export class PetStatusData {
  constructor(public petId: number, public subscriptionStatus: string) {}

  static buildFromJson(json: any) {
    return new PetStatusData(json.pet_id, json.subscription_status);
  }
}

export class PetAPI {
  static async getCurrentLifeStage(dob: string, breedId: number): Promise<string> {
    const response = await axios.get("/api/vets/prototype/pet/current-life-stage", {
      params: { dob: dob, breed: breedId }
    });
    return response.data.resp.life_stage;
  }
  static async getPetData(petId: number): Promise<PetDataDto> {
    const response = await axios.get(`/api/vets/v1/pet/${petId}`);
    return PetDataDto.buildFromJson(response.data.resp);
  }

  static async getCustomerPetsStatusesByAnyPetId(petId: number): Promise<Array<PetStatusData>> {
    const response = await axios.get(`/api/vets/v1/pet/${petId}/customer/pets-statuses-data`);
    return response.data.resp.map((data: any) => PetStatusData.buildFromJson(data));
  }
}
