
import Vue from "vue";
import WithAppBar from "@/layouts/WithAppBar.vue";
import FortifloraImage from "@/components/v2/Fortiflora/FortifloraImage.vue";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import { PetAPI, PetDataDto } from "@/api/v2/pet";
import productService, {
  ProductDescriptionDTO,
  ProductDTO,
  SubscriptionProductDTO
} from "@/services/product.service";
import FortifloraSubscriptionActions from "@/components/v2/Fortiflora/FortifloraSubscriptionActions.vue";
import { RouteNames } from "@/router/routeNames";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default Vue.extend<any, any, any, any>({
  name: "Fortiflora",
  components: {
    BreadCrumbs,
    FortifloraSubscriptionActions,
    FortifloraImage,
    WithAppBar,
    LoadingCircle
  },
  data() {
    return {
      loading: true,
      actionLoading: false,
      petId: +this.$route.params.pet_id,
      customerId: +this.$route.params.customer_id,
      fortiflora: null as ProductDTO | null,
      subscriptionProducts: [] as Array<SubscriptionProductDTO>,
      petData: null as PetDataDto | null,
      mainSection: null as ProductDescriptionDTO | null,
      otherSections: [] as Array<ProductDescriptionDTO>,
      tab: null
    };
  },
  methods: {
    async loadPetData() {
      this.petData = await PetAPI.getPetData(this.petId);
    },
    async loadAllProductsData() {
      await Promise.all([this.loadFortifloraProduct(), this.loadSubscriptionProducts()]);
      this.loading = false;
    },
    async loadFortifloraProduct() {
      const products = await productService.getProducts();
      this.setParsedDescription(products[0]);
    },
    async loadSubscriptionProducts() {
      this.subscriptionProducts = await productService.getSubscriptionProducts(
        this.petData?.subscription.subscriptionId
      );
    },
    setParsedDescription(product: ProductDTO) {
      this.fortiflora = product;
      const sections = product.descriptionSections;
      if (!sections) {
        return;
      }
      const mainSection = sections.find((item: ProductDescriptionDTO) => !item.section);
      const sortedSections = sections
        .filter((item: ProductDescriptionDTO) => item.section)
        .sort((a: any, b: any) => (a.order > b.order ? 1 : -1));

      this.mainSection = mainSection || {
        html: this.fortiflora.description,
        order: 0
      };
      this.otherSections = sortedSections;
    },
    async returnToNutritionalPlan() {
      await this.$router.push({
        name: RouteNames.NUTRITIONAL_PLAN,
        params: { pet_id: this.petId, customer_id: this.customerId }
      });
    },
    async addProduct() {
      this.actionLoading = true;
      await productService.addToSubscription(
        this.petData?.subscription.subscriptionId,
        this.fortiflora?.productIdentifier
      );
      await this.loadSubscriptionProducts();
      this.actionLoading = false;
    },
    async removeProduct() {
      this.actionLoading = true;
      await productService.removeFromSubscription(
        this.petData?.subscription.subscriptionId,
        this.fortiflora?.productIdentifier
      );
      await this.loadSubscriptionProducts();
      this.actionLoading = false;
    }
  },
  computed: {
    isFortifloraAdded(): boolean {
      if (this.fortiflora && this.subscriptionProducts) {
        return this.subscriptionProducts.some(
          (product: SubscriptionProductDTO) =>
            product.productIdentifier === this.fortiflora?.productIdentifier
        );
      }
      return false;
    }
  },
  async mounted() {
    await this.loadPetData();
    await this.loadAllProductsData();
    this.loading = false;
  }
});
