import axios from "axios";

export type ProductDescriptionDTO = {
  html: string;
  section?: string;
  title?: string;
  order: number;
};

export type ProductDTO = {
  title: string;
  description: string | null;
  descriptionSections: Array<ProductDescriptionDTO> | null;
  unit: string;
  price: number;
  slug: string;
  productIdentifier: string;
  imageUri: string | null;
};

export type SubscriptionProductDTO = {
  productIdentifier: string;
  subscriptionProductId: number;
  subscriptionId: number;
  productId: number;
  count: number;
};

export type ProductActionResponse = {
  success: boolean;
};

export class ProductService {
  private readonly productsUrl = "/api/vets/v1/store-products";
  private readonly subscriptionProductUrl = "/api/vets/v1/subscriptions/";

  public getProducts(): Promise<Array<ProductDTO>> {
    return axios.get(this.productsUrl).then(response => {
      const products = response.data.resp.store_products;

      return products.map((product: any) => {
        return {
          title: product.title,
          description: product.description,
          descriptionSections: product.description_sections,
          unit: product.unit,
          price: product.price,
          slug: product.slug,
          productIdentifier: product.product_identifier,
          imageUri: product.image_uri
        };
      });
    });
  }
  public getSubscriptionProducts(subscriptionId: number): Promise<Array<SubscriptionProductDTO>> {
    return axios.get(`${this.subscriptionProductUrl}${subscriptionId}/products`).then(response => {
      const products = response.data.resp.subscription_products;
      return products.map((product: any) => {
        return {
          productIdentifier: product.product_identifier,
          subscriptionProductId: product.subscription_product_item_id,
          subscriptionId: product.subscription_id,
          productId: product.product_id,
          count: product.count
        };
      });
    });
  }
  public addToSubscription(
    subscriptionId: number,
    identifier: string
  ): Promise<ProductActionResponse> {
    return axios
      .put(`${this.subscriptionProductUrl}${subscriptionId}/products`, {
        identifier: identifier
      })
      .then(() => {
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
  public removeFromSubscription(
    subscriptionId: number,
    identifier: string
  ): Promise<ProductActionResponse> {
    return axios
      .delete(`${this.subscriptionProductUrl}${subscriptionId}/products/${identifier}`)
      .then(() => {
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
}

const productService = new ProductService();
export default productService;
