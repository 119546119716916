import axios from "axios";
import moment, { Moment } from "moment";

export class DeliveryWindow {
  dispatch_date: Moment;
  fulfilment: Moment;
  max: Moment;
  min: Moment;

  constructor(rawData: any) {
    this.dispatch_date = moment(rawData.dispatch_date);
    this.fulfilment = moment(rawData.fulfilment);
    this.max = moment(rawData.max);
    this.min = moment(rawData.min);
  }
}

export class CourierService {
  private readonly deliveryWindowUrl = "/api/common/v1/delivery/window";

  public getFirstAvailableWindow(postcode?: string): Promise<DeliveryWindow> {
    let params = {};
    if (postcode) {
      params = { postcode };
    }

    return axios.get(this.deliveryWindowUrl, { params }).then((response: any) => {
      const window = response.data.resp;
      return new DeliveryWindow(window);
    });
  }
}

const courierService = new CourierService();
export default courierService;
