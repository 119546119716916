
import Vue from "vue";
import { formatPrice } from "@/utils/currency";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";

export default Vue.extend<any, any, any, any>({
  name: "FortifloraSubscriptionActions",
  components: { LoadingCircle },
  props: {
    price: Number,
    productActive: Boolean,
    loading: Boolean
  },
  data() {
    return {
      orderDays: 30
    };
  },
  computed: {
    pricePerDay() {
      return this.price / this.orderDays;
    }
  },
  methods: {
    onReturn() {
      this.$emit("onReturn");
    },
    onAddProduct() {
      this.$emit("onAddProduct");
    },
    onRemoveProduct() {
      this.$emit("onRemoveProduct");
    }
  },
  filters: {
    formatPrice: formatPrice
  }
});
